@use '@angular/material' as mat;

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/font/material_icons.woff2') format('woff2');
}

@font-face {
    font-family: 'garamond';
    src: url('/assets/font/garamond.woff2') format('woff2'),
        url('/assets/font/garamond.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

$picmotion-typography: mat.define-typography-config($font-family: 'sans-serif',
        $headline: mat.define-typography-level($font-weight: 500,
            $font-size: 2rem,
            $line-height: 1.5,
            $letter-spacing: normal,
        ),
        $subheading-2: mat.define-typography-level($font-weight: 500,
            $font-size: 1.4rem,
            $line-height: 1.5,
            $letter-spacing: normal,
        ),
        $body-1: mat.define-typography-level($font-weight: 500,
            $font-size: 1.4rem,
            $line-height: 1,
            $letter-spacing: normal,
        ),
        $button: mat.define-typography-level($font-weight: 500,
            $font-size: 1.4rem,
            $line-height: 1,
            $letter-spacing: normal,
        ),
        $input: mat.define-typography-level($font-weight: 500,
            $font-size: 1.4rem,
            $line-height: 1,
            $letter-spacing: normal,
        ),
    );

@include mat.core($picmotion-typography);

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: garamond, "Helvetica Neue", sans-serif;
    color: silver;
}

//MATERIAL OVERRIDES
mat-dialog-container * {
    color: silver;
}

.mat-list-item * {
    color: black;
}

//GENERAL CSS
.header {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 20px;
    width: 100%;

    .title {
        font-family: Garamond sans-serif;
        font-size: xx-large;
        font-style: italic;
        text-align: center;
        text-transform: uppercase;
        color: silver;
    }

    .logo {
        background: url("/assets/yullbe-wunderland-logo-rgb.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 128px;
        height: 128px;

        @media screen and (min-height: 3840px) {
            width: 512px;
            height: 512px;
        }
    }
}


.main {
    background: linear-gradient(60deg, rgba(255, 87, 69, 50%), rgba(144, 73, 229, 50%), rgba(115, 211, 255, 50%)) #000000;
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    user-select: none;
}

.main-container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: inherit;
    height: inherit;
    z-index: -1;
    gap: 20px;
}

.content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    gap: 20px;
    overflow: auto;
    padding: 1rem;
    text-align: center;
    background: #000000;

    &.title {
        font-weight: bold;
    }
}

.button-bar {
    display: flex;
    text-align: center;
    flex-direction: column;
    flex: 1;
    gap: 20px;

    &>button,
    &>a {
        border-radius: 10px;
        min-width: 50%;
        background: #88bccf;
    }
}

.center {
    text-align: center;
    align-self: center;
    justify-self: center;
    line-height: 1.5;
    font-size: 2rem;
    padding: 10px;
}

.error {
    color: red;
}

.image-gallery>.mat-dialog-container {
    background: #000000;
    border-radius: 15px;
}

.miwu-prompt {

    .mat-dialog-container,
    .mat-toolbar {
        background: #000000;
    }
}

.qrcode>canvas {
    border-radius: 10px;
}